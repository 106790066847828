// 50/50 Group

import { useState, forwardRef } from 'react'
import type { MutableRefObject } from 'react'

import { isNotEmptyArray } from 'utils/client'
import { DynamicBackground, HeaderContent } from 'ui'
import { AllSlices } from '@/components/slices'
import type { PageDocumentDataBodyTabSliceGroupSlice } from 'types/types.generated'

const TabSliceGroup = forwardRef(
	(props: PageDocumentDataBodyTabSliceGroupSlice, ref: MutableRefObject<HTMLDivElement>) => {
		const { primary, items, globalData } = props || {}

		const {
			section_heading,
			heading_max_width,
			section_description,
			description_max_width,
			section_primary_cta_label,
			section_primary_cta_link,
			section_secondary_cta_label,
			section_secondary_cta_link,
			background_color,
			background_squeegee_option,
			background_height,
			text_contrast_mode,
			centered
		} = primary || {}

		// Determine which slice to show
		const [selectedIndex, setSelectedIndex] = useState(0)

		return isNotEmptyArray(items) ? (
			<div
				className={`tab-slice-group section relative [&_section]:!pt-0
			${text_contrast_mode === 'light' ? 'text-white' : 'text-black-rt'}
		`}
				ref={ref}
			>
				<DynamicBackground
					background_color={background_color}
					backgroundHeight={background_height}
					backgroundSqueegeeOption={background_squeegee_option}
				/>
				<HeaderContent
					title={section_heading}
					titleMaxWidth={heading_max_width}
					description={section_description}
					descriptionMaxWidth={description_max_width}
					primaryCta={{
						label: section_primary_cta_label,
						link: section_primary_cta_link
					}}
					secondaryCta={{
						label: section_secondary_cta_label,
						link: section_secondary_cta_link
					}}
					textContrast={text_contrast_mode}
					centered={centered}
					globalData={globalData}
				/>

				{/* Tabs */}
				<div className="top-tabs-container container">
					{items?.map((item, i) => {
						const isSelected = i === selectedIndex

						// Tab title
						return item?.tab_name ? (
							<button
								className={`top-tab ${isSelected && 'is-active'}`}
								onClick={() => setSelectedIndex(i)}
								key={i}
							>
								{item.tab_name}
							</button>
						) : null
					})}
				</div>

				{/* Slices */}
				{items.map((item: any, i) => {
					const isSelected = i === selectedIndex

					return item && isSelected ? (
						<AllSlices page={{ ...item.slice, globalData: globalData }} key={i} />
					) : null
				})}
			</div>
		) : null
	}
)
TabSliceGroup.displayName = 'TabSliceGroup'

export default TabSliceGroup
